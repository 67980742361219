<template>
	<div v-if="drawer">
		<v-navigation-drawer
			v-model="drawer"
			fixed
			width="735"
			right
			temporary
			stateless
			style="z-index: 200"
		>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">Send Mail</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						class="blue darken-4"
						:disabled="pageLoading || !formValid"
						v-on:click="submit()"
						>Send</v-btn
					>
				</v-flex>
				<v-form
					ref="meetingForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="update_or_create"
				>
					<div class="drawer-content pt-5 px-5">
						<v-row>
							<v-col cols="12" md="2" class="my-auto">
								<label for="email " class="btx-label required">Recipients</label>
							</v-col>
							<v-col cols="12" md="10" class="my-auto py-0">
								<div class="d-flex align-items-end">
									<ComboboxInput
										v-model="recipients"
										hide-details
										:items="attendies"
										outlined
										placeholder="Recipients"
										dense
										deletable-chips
										small-chips
										:disabled="pageLoading"
										:loading="pageLoading"
										color="blue darken-4"
										multiple
										:rules="[vrules.required(recipients, 'Recipients')]"
										:class="{
											required: !recipients,
										}"
										class="autocomplete-multiple"
										v-on:change="validateEmail($event)"
									></ComboboxInput>
									<v-btn
										style="height: 30px !important"
										tile
										depressed
										color="green white--text"
										@click="isCC = !isCC"
									>
										CC
									</v-btn>
								</div>
							</v-col>
							<template v-if="isCC">
								<v-col cols="12" md="2" class="my-auto">
									<label for="email " class="btx-label">CC</label>
								</v-col>
								<v-col cols="12" md="10" class="my-auto py-0">
									<ComboboxInput
										v-model="ccRecipients"
										hide-details
										:items="attendies"
										outlined
										placeholder="CC"
										dense
										small-chips
										deletable-chips
										color="blue darken-4"
										multiple
										:disabled="pageLoading"
										:loading="pageLoading"
										class="autocomplete-multiple"
										v-on:change="validateCCEmail($event)"
									></ComboboxInput>
								</v-col>
							</template>
							<v-col cols="12" md="2" class="my-auto">
								<label for="subject " class="btx-label required">Subject</label>
							</v-col>
							<v-col cols="12" md="10" class="my-auto py-0">
								<TextInput
									hide-details
									id="subject"
									placeholder="Subject"
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="subject"
									:rules="[vrules.required(subject, 'Subject')]"
									:class="{
										required: !subject,
									}"
								>
								</TextInput>
							</v-col>
							<v-col cols="12" md="2" class="mt-3 py-0">
								<label for="message" class="btx-label required">Message</label>
							</v-col>
							<v-col cols="12" md="10" class="my-auto py-0 mt-2">
								<ckeditor
									:editor="editor"
									v-model="editorData"
									:config="editorConfig"
									:class="{
										required: !editorData,
									}"
								></ckeditor>
							</v-col>
							<v-col cols="12" md="2" class="my-auto">
								<label for="subject " class="btx-label">Attachment</label>
							</v-col>
							<v-col cols="12" md="10" class="my-auto py-0 mt-2">
								<template v-for="(file, index) in files">
									<div class="d-flex mb-2" :key="index">
										<div class="w-40 pr-3" style="margin-top: -12px; overflow: hidden">
											<v-file-input
												:id="`document-file-${index}`"
												placeholder="Select File"
												outlined
												class="mt-3"
												prepend-icon=""
												prepend-inner-icon="mdi-attachment"
												hide-details
												v-model="file.file"
												v-on:change="updateFile(index, $event)"
												v-on:click:clear="updateFile(index, $event)"
											></v-file-input>
										</div>
										<div class="w-40 pr-3" style="margin-top: -12px">
											<TextInput
												:id="`document-name-${index}`"
												v-model="file.name"
												hide-details
												placeholder="File Name"
												:suffix="file.suffix"
											></TextInput>
										</div>
										<div class="w-10 pr-3" style="margin-top: -12px">
											<v-btn
												:disabled="files.length < 2"
												v-on:click="removeFile(index)"
												color="red lighten-1 white--text"
												class="mt-3"
												icon
											>
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</div>
										<div class="w-10 pr-3" style="margin-top: -12px">
											<v-btn
												color="blue darken-4 white--text"
												class="mt-3 ml-2"
												tile
												depressed
												v-on:click="addMore()"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</div>
									</div>
								</template>
							</v-col>
						</v-row>
					</div>
				</v-form>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<style scoped>
.v-dialog__content.v-dialog__content--active {
	z-index: 202 !important;
}
</style>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { find, toString } from "lodash";
import { GET, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import TextInput from "@/view/components/TextInput";

import { mapGetters } from "vuex";
import ComboboxInput from "@/view/components/ComboboxInput";

export default {
	mixins: [MainMixin],
	name: "CreateMeeting",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		getMeetingData: {
			type: Object,
		},
		attendies: {
			type: Object,
		},
		related: {
			type: String,
			default: null,
		},
		relatedId: {
			type: Number,
			default: 0,
		},
		titleStatus: {
			type: String,
		},
	},
	watch: {
		attendies(param) {
			this.recipients = param;
		},

		/* related:{
			deep: true,
			immediate: true,
			handler(param) {
				if(this.param == 'task'){
			      this.meetin.related_to ='task'
		}
			},

		} */
	},
	components: {
		TextInput,
		/* 	AutoCompleteInput, */
		/* 	CreateInternalLead, */
		ComboboxInput,
	},
	data() {
		return {
			pageLoading: false,
			formValid: true,
			userList: [],
			isCC: false,
			recipients: [],
			ccRecipients: [],
			files: [
				{
					file: null,
					name: null,
				},
			],
			subject: null,
			editor: ClassicEditor,
			editorData:
				"<p><strong>Dear Admin,</strong></p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p><p>when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>",
			editorMom: "<p>MOM</p>",
			editorConfig: {
				// The configuration of the editor.
			},
		};
	},
	methods: {
		submit() {
			const _this = this;
			_this.pageLoading = true;
			/* _this.email.attachments = _this.attachments; */
			let formData = new FormData();

			formData.append("subject", this.subject ? this.subject : null);
			if (this.editorData) {
				formData.append("message", this.editorData ? this.editorData : null);
			}
			for (let i = 0; i < this.recipients.length; i++) {
				if (this.recipients && this.recipients[i] && this.recipients[i]) {
					formData.append(`recipients[${i}]`, this.recipients[i]);
				}
			}
			for (let i = 0; i < this.ccRecipients.length; i++) {
				if (this.ccRecipients && this.ccRecipients[i] && this.ccRecipients[i]) {
					formData.append(`cc[${i}]`, this.ccRecipients[i]);
				}
			}
			for (let i = 0; i < this.files.length; i++) {
				console.log(this.files[i]);
				if (this.files && this.files[i] && this.files[i].file) {
					formData.append(`file[${i}][file]`, this.files[i].file);
				}
				if (this.files && this.files[i] && this.files[i].name) {
					formData.append(`file[${i}][name]`, this.files[i].name);
				}
			}
			_this.$store
				.dispatch(POST, {
					url: `${this.related}/${this.relatedId}/send-mail`,
					data: formData,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [{ model: true, message: "Send mail successfully" }]);
					this.$emit("close", true);
					this.$emit("scucess", true);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},

		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		getMeetingSetting() {
			this.$store
				.dispatch(GET, { url: `meeting-setting` })
				.then((data) => {
					this.userList = data.users;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		validateEmail(params) {
			if (params.length) {
				const email = params[params.length - 1];
				console.log(email);
				//eslint-disable-next-line
				const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				const isValid = re.test(toString(email).toLowerCase());
				const exists = find(params, (row) => row == email);
				if (!isValid && exists) {
					params.pop();
				}
				this.recipients = params;
			}
		},
		validateCCEmail(params) {
			if (params.length) {
				const email = params[params.length - 1];
				//eslint-disable-next-line
				const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				const isValid = re.test(toString(email).toLowerCase());
				const exists = find(params, (row) => row == email);
				if (!isValid && exists) {
					params.pop();
				}
				this.ccRecipients = params;
			}
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
	mounted() {},
	beforeMount() {},
};
</script>
<style scoped></style>

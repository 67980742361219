<template>
	<v-sheet class="main-detail">
		<div class="">
			<v-layout class="section-main-content">
				<div class="listing-sidebar border-right" style="height: calc(100vh - 72.5px)">
					<div class="listing-header d-flex align-center py-1">
						<v-menu offset-y right bottom content-class="elevation-4 rounded-lg" rounded="0">
							<template v-slot:activator="{ on, attrs }">
								<p class="sub-heading m-0" v-on="on" v-bind="attrs">
									{{ currentStatus.text }}
									<v-icon>mdi-menu-down</v-icon>
								</p>
							</template>
							<v-list>
								<v-list-item-group>
									<v-list-item
										v-for="(status, index) in mettingStatus"
										:key="index"
										@click="(page = 1), statusFilter(status.value, status.text)"
									>
										<v-list-item-title class="d-flex align-items-center">
											{{ status.text }}<span class="circle" :class="status.status_color"></span>
											<v-spacer></v-spacer>
											<div class="cm-budge white--text" :class="status.status_color">
												{{ status.status_count }}
											</div>
										</v-list-item-title>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-menu>
						<v-spacer></v-spacer>
						<!-- <v-btn
							depressed
							tile
							color="blue darken-4"
							class="white--text mr-2"
							@click="search_open = !search_open"
						>
							<v-icon class="icon-16px"> fa fa-search </v-icon></v-btn
						> -->
						<!-- <v-btn
							depressed
							tile
							color="blue darken-4"
							class="white--text"
							v-on:click="open_reminder_drawer('Create')"
						>
							<v-icon>mdi-plus</v-icon> New</v-btn
						> -->
						<div class="d-flex align-center mr-2 my-1 fw-600">
							<div>Current Records</div>
							<v-chip color="primary" label outlined x-small class="ml-1 px-1">
								<span class="fw-600 fs-12">
									{{ statusData.length }}
								</span>
							</v-chip>
						</div>
					</div>
					<div class="sidebarsearch-wrapper" :class="search_open ? 'open' : ''">
						<div class="sidebarsearch">
							<v-text-field
								hide-details
								outlined
								placeholder="Search..."
								v-model="search"
								v-on:keyup="searchMeeting($event)"
							>
								<v-icon slot="append" @click="closeSerach"> mdi-close </v-icon>
							</v-text-field>
						</div>
					</div>

					<div
						class="side-listing listing-sidebar-list"
						:style="{ height: `calc(100vh - ${showLoadMoreBtn() ? '145px' : '113px'})` }"
					>
						<div class="listing-innerlist px-1">
							<div
								class="list-items rounded-lg my-1 elevation-3"
								v-for="(data, index) in statusData"
								:key="index"
								:class="data.id == meetingId ? `active ${getStatusActiveColor(data.status)}` : ''"
								@click="tabDetails(data.id)"
							>
								<!-- <div class="ellipsis pr-6">
										<div class="ellipsis pr-6">
											<span class="fw-600 pr-2">Meeting # : </span>
											<span class="fw-500">{{ data.barcode }}</span>
										</div>
								</div> -->
								<div class="d-flex">
									<div class="fw-500 mr-2">
										<!-- <span class="fw-600 pr-2"># : </span> -->
										<template v-if="data && data.barcode">
											<v-chip
												class="text-capitalize mr-2 px-1"
												color="blue white--text"
												x-small
												outlined
												label
											>
												{{ data.barcode }}
											</v-chip>
										</template>
										<template v-else>no barcode</template>
									</div>
									<v-spacer></v-spacer>
									<div class="ellipsis fw-500 text-end">
										<template v-if="data && data.related_to">
											<v-chip class="mb-1 mr-1 px-1" label :color="getStatusColorBy(data.status)" dark x-small>
												{{ data.status }}
											</v-chip>
										</template>
									</div>
									<div class="ellipsis fw-500 text-end">
										<template v-if="data && data.related_to">
											<span class="fw-500">
												<v-chip
													class="ml-2 text-capitalize px-1"
													color="red white--text"
													x-small
													label
													v-if="data && data.related_to"
													>{{ data.related_to.replace("-", " ") }}</v-chip
												></span
											>
										</template>
									</div>
								</div>

								<div class="d-flex">
									<div class="ellipsis pr-2">
										<div class="ellipsis pr-2 h6 my-1">
											<!-- <span class="fw-600 pr-2">Title: </span> -->
											<span class="fw-600">{{ data.title }}</span>
										</div>
									</div>
									<v-spacer></v-spacer>
								</div>

								<div class="ellipsis pr-6">
									<div class="pr-6">
										<span class="fw-500 text-muted pr-2 fs-12">Date: </span>
										<span class="fw-500 fs-12">{{ formatDateTime(data.start_from) }}</span>
										<!-- <v-chip
											class="text-capitalize mr-2"
											color="blue white--text"
											x-small
											outlined
											label
											v-if="data && data.start_from"
											>
											{{formatDateTime(data.start_from)}}
										</v-chip> -->
									</div>
								</div>
								<!-- <div class="ellipsis pr-6" v-if="data && data.lead_barcode && data.related_to == 'lead'">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Lead # : </span>
										<template v-if="data && data.lead_barcode">
											<span class="fw-500">{{ data.lead_barcode }}</span>
										</template>
									</div>
								</div> -->
								<div class="ellipsis pr-6" v-if="data && data.related && data.related_to == 'lead'">
									<div class="ellipsis pr-6">
										<span class="fw-500 text-muted pr-2 fs-12">Company Name : </span>
										<template v-if="data && data.related">
											<span class="fw-500 fs-12">{{ data.related }}</span>
										</template>
										<em class="text-muted" v-else> no company name </em>
									</div>
								</div>
								<div
									class="ellipsis pr-6"
									v-if="data && data.lead_customer_name && data.related_to == 'lead'"
								>
									<div class="ellipsis pr-6" v-if="false">
										<span class="fw-500 text-muted pr-2 fs-12">Person Incharge : </span>
										<template v-if="data && data.lead_customer_name">
											<span class="fw-500 fs-12">{{ data.lead_customer_name }}</span>
										</template>
										<em class="text-muted" v-else> no person incharge </em>
									</div>
								</div>
								<div class="ellipsis pr-6" v-if="data && data.related && data.related_to == 'customer'">
									<div class="ellipsis pr-6">
										<span class="fw-500 text-muted pr-2 fs-12">Company : </span>
										<template v-if="data && data.related">
											<span class="fw-500 fs-12">{{ data.related }}</span>
										</template>
										<em class="text-muted" v-else> no company name </em>
									</div>
								</div>
								<!-- <div class="ellipsis pr-6" v-if="data && data.lead_date && data.related_to == 'lead'">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Lead Date : </span>
										<template v-if="data && data.lead_date">
											<span class="fw-500">{{ formatDate(data.lead_date) }}</span>
										</template>
										<em class="text-muted" v-else> no lead date </em>
									</div>
								</div>
								<div
									class="ellipsis pr-6"
									v-if="data && data.customer_barcode && data.related_to == 'customer'"
								>
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Customer # : </span>
										<template v-if="data && data.customer_barcode">
											<span class="fw-500">{{ data.customer_barcode }}</span>
										</template>
									</div>
								</div>
								<div class="ellipsis pr-6" v-if="data && data.related && data.related_to == 'customer'">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Person Incharge : </span>
										<template v-if="data && data.contacts && data.contacts[0] && data.contacts[0].name">
											<span class="fw-500">{{ data.contacts[0].name }}</span>
										</template>
										<em class="text-muted" v-else> no person incharge </em>
									</div>
								</div> -->
								<div class="ellipsis pr-6">
									<div class="ellipsis pr-6">
										<span class="fw-500 text-muted pr-2 fs-12">Meeting Type: </span>
										<template v-if="data && data.meeting_type == 'zoom'">
											<v-chip
												class="ml-2 text-capitalize mr-2 px-1"
												color="blue white--text"
												x-small
												outlined
												label
												v-if="data && data.meeting_type"
											>
												<span class="fs-12">{{ data.meeting_type }}</span>
											</v-chip>
											<a :href="data.host_url" target="_blank" rel="noopener noreferrer">Meeting URL</a>
										</template>
										<template v-if="data && data.meeting_type == 'bthrust'">
											<v-chip
												class="ml-2 text-capitalize px-1"
												color="red white--text"
												x-small
												outlined
												label
												v-if="data && data.meeting_type"
											>
												<span class="fs-12">F2F Bthrust</span>
											</v-chip>
										</template>
										<template v-if="data && data.meeting_type == 'client'">
											<v-chip
												class="ml-2 text-capitalize px-1"
												color="green white--text"
												x-small
												label
												outlined
												v-if="data && data.meeting_type"
											>
												<span class="fs-12">F2F Client</span>
											</v-chip>
										</template>
										<template v-if="data && data.meeting_type == 'other'">
											<v-chip
												class="ml-2 text-capitalize px-1"
												color="orange white--text"
												x-small
												outlined
												label
												v-if="data && data.meeting_type"
											>
												<span class="fs-12">Zoom</span>
											</v-chip>
										</template>
										<template v-if="data && data.meeting_type == 'other2'">
											<v-chip
												class="ml-2 text-capitalize px-1"
												color="orange white--text"
												x-small
												outlined
												label
												v-if="data && data.meeting_type"
											>
												<span class="fs-12">Other</span>
											</v-chip>
										</template>
									</div>
								</div>
								<!-- <div class="" v-if="data.meeting_type != 'zoom'">
									<div class="pr-6 d-flex">
										<div class="fw-600 pr-2 w-27">Location/URL:</div>
										<div class="fw-500 text-capitalize w-70" v-if="data && data.location">
											{{ data.location }}
										</div>
									</div>
								</div> -->
								<div class="ellipsis pt-2">
									<span class="fw-500 pr-2 text-muted fs-12">Attendees: </span>
									<template v-if="data && data.attendies.length">
										<template v-for="(row, index) in data.attendies">
											<span v-if="index < 3" :key="index + 'avatar'">
												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<v-avatar
															v-on="on"
															v-bind="attrs"
															size="24"
															class="white"
															:class="{
																'ml-n3': index,
															}"
															style="border: 1px solid grey !important"
														>
															<v-img v-if="row.image" :src="row.image"></v-img>
															<span v-else class="text-uppercase">{{
																row.display_name && row.display_name.length > 1 ? row.display_name.slice(0, 2) : ""
															}}</span>
														</v-avatar>
													</template>
													<span>{{ row.display_name }}</span>
												</v-tooltip>
											</span>
											<span v-if="index == 3" :key="index + 'more'">
												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<v-avatar
															v-on="on"
															v-bind="attrs"
															size="30"
															style="border: 1px solid grey !important"
															class="ml-n3 white"
														>
															<span> + {{ data.attendies.length - 3 }} </span>
														</v-avatar>
													</template>
													<span>{{ data.attendies.length - 3 }} More</span>
												</v-tooltip>
											</span>
										</template>
									</template>
									<template
										v-if="
											false &&
											data &&
											data.attendies[0] &&
											data.attendies[0].display_name &&
											data.attendies.length
										"
									>
										<span class="fw-500">
											({{ data.attendies[0].display_name }})
											<template v-if="data.attendies.length > 1">
												+{{ data.attendies.length - 1 }} More
											</template>
										</span>
									</template>
								</div>
								<!-- <div class="d-flex align-items-center mt-1">
									<span v-for="(row, index) in data.attendies" :key="index">
										<v-tooltip v-if="index < 4" right content-class="custom-right-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<v-avatar
													v-if="row.image"
													v-on="on"
													v-bind="attrs"
													width="30px"
													min-width="30px"
													height="30px"
													class="pa-0 mr-1 lighten-3 white--text orange"
													left
												>
													<v-img :src="row.image" alt="John"></v-img>
												</v-avatar>

												<v-avatar
													v-else-if="row.name"
													v-on="on"
													v-bind="attrs"
													width="30px"
													min-width="30px"
													height="30px"
													class="pa-0 mr-1 lighten-3 white--text orange"
													left
													v-text="row.name.slice(0, 1).toUpperCase()"
												></v-avatar>
											</template>
											<span>{{ row.name }}</span>
										</v-tooltip>
										<span v-if="index === 4">
											<v-menu bottom offset-y :close-on-content-click="false">
												<template v-slot:activator="{ on, attrs }">
													<span v-on="on" v-bind="attrs" class=""> + {{ data.attendies.length - 4 }} More </span>
												</template>
												<div class="pa-3 elevation-2 white" style="max-width: 400px">
													<ProjectMember class-name="members_in_listing"></ProjectMember>
												</div>
											</v-menu>
										</span>
									</span>
								</div> -->
								<!-- <v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
							</div>
						</div>
					</div>

					<div class="text-center my-2" v-if="showLoadMoreBtn()">
						<v-tooltip top content-class="custom-top-tooltip">
							<template #activator="{ on, attrs }">
								<v-btn
									color="blue darken-4"
									dark
									v-on="on"
									v-bind="attrs"
									max-height="20"
									class="py-0 px-2"
									small
									@click="loadMoreMeetings"
								>
									<template v-if="pageLoading">Loading...</template>
									<template v-else>
										<v-icon left class="mr-1" size="15">mdi-reload</v-icon>
										<span>Load more</span>
									</template>
								</v-btn>
							</template>
							<span>Load more meetings</span>
						</v-tooltip>
					</div>
					<!-- customer details -->

					<!-- customer details end -->
				</div>
				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="align-center light-blue-bg px-4 py-2 flex-wrap">
							<!--p class="my-auto font-level-3-bold">
								<v-text-field hide-details outlined class="mt-0 pt-0"></v-text-field>
							</p-->
							<v-chip
								class="mr-1 mb-1 text-capitalize"
								color="cyan white--text"
								x-small
								label
								v-if="this.meetingData && this.meetingData.barcode"
							>
								{{ this.meetingData.barcode }}
							</v-chip>
							<v-chip class="mb-1 mr-1" label :color="`${getStatusColor()} white--text`" x-small>
								{{ getStatusText() }}
							</v-chip>
							<v-chip class="mb-1 mr-1" label color="red white--text" x-small>
								{{ meetingData.related_to }}
							</v-chip>
							<v-spacer></v-spacer>
							<!-- <v-icon
								style="font-size: 30px; margin-top: -6px"
								color="green"
								small
								class="mr-2"
								v-on:click="meetingDialog = true"
								>mdi-chat
							</v-icon> -->
							<v-btn
								v-if="getPermission('meeting:update') && meetingData.status != 1 && meetingData.status != 2"
								color="blue darken-4 text-white"
								class="mr-2"
								depressed
								tile
								v-on:click="doAction('edit')"
								><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
							>
							<v-menu
								offset-y
								left
								bottom
								content-class="elevation-3"
								rounded="0"
								v-if="meetingData.status != 1 && meetingData.status != 2"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										color="blue darken-4 text-white"
										v-on="on"
										v-bind="attrs"
										class="mr-2"
										depressed
										tile
										><v-icon small left>mdi-more mdi-flip-h</v-icon>More</v-btn
									>
								</template>

								<v-list>
									<!-- <v-list-item v-on:click="doAction('edit')">
										<v-list-item-title>
											<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
											Edit</v-list-item-title
										>
									</v-list-item> -->
									<v-list-item v-on:click="doAction('mark_as_cancelled')">
										<v-list-item-title v-if="meetingData.status != 2 && meetingData.status != 5">
											<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
											Mark As Cancel</v-list-item-title
										>
									</v-list-item>
									<v-list-item v-on:click="doAction('mark_as_postponed')">
										<v-list-item-title v-if="meetingData.status != 3 && meetingData.status != 5">
											<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
											Mark As Postponed</v-list-item-title
										>
									</v-list-item>
									<v-list-item v-on:click="doAction('mark_as_completed')">
										<v-list-item-title v-if="meetingData.status != 1">
											<v-icon small left color="blue darken-4">mdi-orbit-variant</v-icon>
											Mark As Completed</v-list-item-title
										>
									</v-list-item>
								</v-list>
							</v-menu>
							<!-- <div v-if="assetTab == 'activity'">
								<v-btn
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text"
									v-on:click="userListing()"
								>
									<v-icon class="mr-1">mdi-account-group-outline</v-icon>
									All Users
								</v-btn>
							</div> -->
							<div v-if="false">
								<v-btn
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text "
									@click="open_dialog.notes = true"
								>
									<v-icon class="mr-1">mdi-plus</v-icon>
									Create
								</v-btn>
							</div>
							<div v-if="assetTab == 'task'">
								<v-btn
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text "
									@click="open_dialog.task = true"
								>
									<v-icon class="mr-1">mdi-plus</v-icon>
									Create Task
								</v-btn>
							</div>
							<div v-if="assetTab == 'meeting'">
								<v-btn
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text "
									@click="open_dialog.meeting = true"
								>
									<v-icon class="mr-1">mdi-plus</v-icon>
									Create Meeting
								</v-btn>
							</div>
							<div v-if="assetTab == 'reminder'">
								<v-btn
									v-if="getPermission('reminder:create')"
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text "
									@click="create_reminder"
									:disabled="getStatusText() == 'Completed' || getStatusText() == 'Cancelled' ? true : false"
								>
									<v-icon class="mr-1">mdi-plus</v-icon>
									Create Reminder
								</v-btn>
							</div>
							<v-btn depressed tile class="my-auto" v-on:click="goBackData()">
								<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
								back
							</v-btn>
						</v-layout>
						<div class="px-4">
							<div>
								<p
									class="my-auto fw-700 text-capitalize dark-blue--text fs-20 ml-0"
									v-if="meetingData && meetingData.title"
								>
									{{ meetingData.title }}
								</p>
								<em v-else class="text-muted"> No title </em>
							</div>
							<div class="py-2">
								Date :
								<span class="fw-500"
									>{{ formatDate(meetingData.start_from) }} {{ meetingData.start_time }} ({{
										meetingData.duration
									}})</span
								>
								<template v-if="meetingData && meetingData.meeting_type == 'zoom'">
									<v-chip
										class="ml-2 text-capitalize"
										color="blue white--text"
										outlined
										x-small
										label
										v-if="meetingData && meetingData.meeting_type"
										>{{ meetingData.meeting_type }}
									</v-chip>
								</template>
								<template v-if="meetingData && meetingData.meeting_type == 'bthrust'">
									<v-chip
										class="ml-2 text-capitalize"
										color="red white--text"
										x-small
										outlined
										label
										v-if="meetingData && meetingData.meeting_type"
										>F2F Bthrust
									</v-chip>
								</template>
								<template v-if="meetingData && meetingData.meeting_type == 'client'">
									<v-chip
										class="ml-2 text-capitalize"
										color="green white--text"
										outlined
										x-small
										label
										v-if="meetingData && meetingData.meeting_type"
										>F2F Client
									</v-chip>
								</template>
								<template v-if="meetingData && meetingData.meeting_type == 'other'">
									<v-chip
										class="ml-2 text-capitalize"
										color="orange white--text"
										x-small
										outlined
										label
										v-if="meetingData && meetingData.meeting_type"
										>Zoom
									</v-chip>
								</template>
							</div>
						</div>
						<v-layout class="px-4 py-2">
							<v-tabs
								v-model="assetTab"
								background-color="transparent"
								color="blue"
								class="custom-tab-transparent w-100"
								active-class="blue darken-4 text-white"
								hide-slider
							>
								<v-tab href="#overview"
									><v-icon small left>mdi-information-outline<!-- mdi-wrench-outline --></v-icon
									>Overview</v-tab
								>
								<v-tab href="#notes" v-if="false"
									><v-icon small left>mdi-note-plus-outline</v-icon>Notes</v-tab
								>
								<v-tab href="#reminder"> <v-icon small left>mdi-alarm-check</v-icon>Reminder</v-tab>
								<v-tab href="#timestamp"> <v-icon small left>mdi-alarm-check</v-icon>Timestamp</v-tab>
								<v-tab href="#file"><v-icon small left>mdi-file-document-multiple</v-icon>Files</v-tab>
								<v-tab href="#activity" v-on:click="meetingActivity()"
									><v-icon small left>mdi-wrench-outline</v-icon>Activity</v-tab
								>
								<v-tab href="#map" v-if="false"><v-icon small left>mdi-map-marker</v-icon>Map</v-tab>
							</v-tabs>
						</v-layout>
					</div>
					<div class="tab-layout-content px-4">
						<v-tabs-items v-model="assetTab">
							<v-tab-item value="overview">
								<v-flex md12>
									<v-row>
										<v-col cols="12" md="4" lg="4" v-if="false">
											<div class="mt-2 py-0 d-flex">
												<span class="fw-600 mr-2 text-no-wrap">Title : </span>
												<span class="fw-500" v-if="meetingData && meetingData.title">
													{{ meetingData.title }}</span
												>
											</div>
											<div class="d-flex mt-2 py-0">
												<span class="fw-600 mr-2">Duration : </span>

												<template v-if="meetingData && meetingData.duration">
													<span class="fw-500">{{ meetingData.duration }}</span>
												</template>

												<template v-else> <em>no duration</em></template>
											</div>
											<div class="mt-2 py-0">
												<span class="fw-600 mr-2"><!-- Check In -->Start Date : </span>
												<template v-if="meetingData && meetingData.start_from && meetingData.start_time">
													<span class="fw-500"
														>{{ formatDate(meetingData.start_from) }} {{ meetingData.start_time }}</span
													>
												</template>
												<template v-else> <em>no check in</em></template>
											</div>
											<div class="mt-2 py-0">
												<span class="fw-600 mr-2"><!-- Check Out -->End Date : </span>
												<template v-if="meetingData && meetingData.end_to && meetingData.end_time">
													<span class="fw-500"
														>{{ formatDate(meetingData.end_to) }} {{ meetingData.end_time }}</span
													>
												</template>
												<template v-else> <em>no check out</em></template>
											</div>
										</v-col>
										<v-col cols="12" md="6" lg="6">
											<div class="py-0 mt-2">
												<span class="fw-600 mr-2">Reminder Date: </span>
												<template v-if="remindersdata && remindersdata[0] && remindersdata[0]?.reminder_date">
													<span class="fw-500">{{ formatDateTime(remindersdata[0]?.reminder_date) }}</span>
													<v-chip
														class="ml-2 text-capitalize"
														color="red white--text"
														x-small
														label
														v-if="remindersdata[0].reminder_status == 1"
														>Overdue
													</v-chip>
												</template>
												<template v-else> no reminder date</template>
											</div>
											<!-- <div
												class="py-0"
												v-if="
													remindersdata &&
													remindersdata[0] &&
													remindersdata[0].reminder_end_date &&
													
												"
											>
												<span class="fw-600 mr-2">Reminder End Date : </span>
												<span
													class="fw-500"
													v-if="remindersdata && remindersdata[0] && remindersdata[0].reminder_end_date"
												>
													{{ formatDate(remindersdata[0].reminder_end_date) }}
												</span>
												<span v-else> no reminder end date</span>
											</div> -->
											<div class="py-0 mt-2">
												<span class="fw-600 mr-2">Reminder Type: </span>
												<span
													class="fw-500"
													v-if="remindersdata && remindersdata[0] && remindersdata[0]?.reminder_type"
												>
													{{ getReminderType(remindersdata[0].reminder_type) }}
												</span>
												<span v-else> no reminder Type</span>
											</div>
											<div class="mt-2 py-0" v-if="false">
												<span class="fw-600 mr-2">Meeting Type: </span>
												<!-- <span class="fw-500" v-if="meetingData && meetingData.title">
													<template v-if="meetingData && meetingData.meeting_type == 'zoom'"> Zoom </template>
													<template v-if="meetingData && meetingData.meeting_type == 'bthrust'">
														Face to Face Bthrust
													</template>
													<template v-if="meetingData && meetingData.meeting_type == 'client'">
														Face to Face Client
													</template>
													<template v-if="meetingData && meetingData.meeting_type == 'other'">
														Other
													</template></span
												> -->
												<template v-if="meetingData && meetingData.meeting_type == 'zoom'">
													<v-chip
														class="ml-2 text-capitalize"
														color="blue white--text"
														outlined
														x-small
														label
														v-if="meetingData && meetingData.meeting_type"
														>{{ meetingData.meeting_type }}
													</v-chip>
												</template>
												<template v-if="meetingData && meetingData.meeting_type == 'bthrust'">
													<v-chip
														class="ml-2 text-capitalize"
														color="red white--text"
														x-small
														outlined
														label
														v-if="meetingData && meetingData.meeting_type"
														>F2F Bthrust
													</v-chip>
												</template>
												<template v-if="meetingData && meetingData.meeting_type == 'client'">
													<v-chip
														class="ml-2 text-capitalize"
														color="green white--text"
														outlined
														x-small
														label
														v-if="meetingData && meetingData.meeting_type"
														>F2F Client
													</v-chip>
												</template>
												<template v-if="meetingData && meetingData.meeting_type == 'other'">
													<v-chip
														class="ml-2 text-capitalize"
														color="orange white--text"
														x-small
														outlined
														label
														v-if="meetingData && meetingData.meeting_type"
														>Zoom
													</v-chip>
												</template>
											</div>
										</v-col>
										<v-col cols="12" md="6" lg="6" v-if="this.meetingData.related_to == 'task'">
											<div class="mt-2 py-0">
												<span class="fw-600 mr-2">Task #: </span>
												<span class="fw-500" v-if="meetingData && meetingData.task_barcode">
													{{ meetingData.task_barcode }}</span
												>
											</div>
											<div class="d-flex mt-2 py-0">
												<span class="fw-600 mr-2">Task Title: </span>

												<template v-if="meetingData && meetingData.task_title">
													<span class="fw-500">{{ meetingData.task_title }}</span>
												</template>

												<template v-else> <em>no task Title</em></template>
											</div>
											<div class="mt-2 py-0">
												<span class="fw-600 mr-2">Task Start Date: </span>
												<template v-if="meetingData && meetingData.task_start_date">
													<span class="fw-500">{{ formatDate(meetingData.task_start_date) }}</span>
												</template>
												<template v-else> <em>no task start date</em></template>
											</div>
											<div class="mt-2 py-0">
												<span class="fw-600 mr-2">Task Due Date: </span>
												<template v-if="meetingData && meetingData.task_due_date">
													<span class="fw-500">{{ formatDate(meetingData.task_due_date) }}</span>
												</template>
												<template v-else> <em>no task due date</em></template>
											</div>
										</v-col>
										<v-col cols="12" md="4" lg="4" v-if="this.meetingData.related_to == 'lead'">
											<div class="mt-2 py-0">
												<span class="fw-600 mr-2">Lead #: </span>
												<span class="fw-500" v-if="meetingData && meetingData.lead_barcode">
													{{ meetingData.lead_barcode }}</span
												>
											</div>
											<div class="d-flex mt-2 py-0">
												<span class="fw-600 mr-2">Company Name: </span>

												<template v-if="meetingData && meetingData.lead_company_name">
													<span class="fw-500">{{ meetingData.lead_company_name }}</span>
												</template>

												<template v-else> <em>no company name</em></template>
											</div>
											<div class="mt-2 py-0" v-if="false">
												<span class="fw-600 mr-2">Person Incharge: </span>
												<template v-if="meetingData && meetingData.lead_customer_name">
													<span class="fw-500">{{ meetingData.lead_customer_name }}</span>
												</template>
												<template v-else> <em>no person incharge</em></template>
											</div>
											<div class="mt-2 py-0">
												<span class="fw-600 mr-2">Lead Date: </span>
												<template v-if="meetingData && meetingData.lead_date">
													<span class="fw-500">{{ formatDate(meetingData.lead_date) }}</span>
												</template>
												<template v-else> <em>no lead date</em></template>
											</div>
										</v-col>
										<v-col cols="12" md="4" lg="4" v-if="this.meetingData.related_to == 'customer'">
											<div class="mt-2 py-0">
												<span class="fw-600 mr-2">Customer #: </span>
												<span class="fw-500" v-if="meetingData && meetingData.customer_barcode">
													{{ meetingData.customer_barcode }}</span
												>
											</div>
											<div class="d-flex mt-2 py-0">
												<span class="fw-600 mr-2">Company Name: </span>

												<template v-if="meetingData && meetingData.customer_company_name">
													<span class="fw-500">{{ meetingData.customer_company_name }}</span>
												</template>

												<template v-else> <em>no company name</em></template>
											</div>
											<div class="mt-2 py-0" v-if="false">
												<span class="fw-600 mr-2">Person Incharge: </span>
												<template
													v-if="
														meetingData &&
														meetingData.contacts &&
														meetingData.contacts[0] &&
														meetingData.contacts[0].name
													"
												>
													<span class="fw-500">{{ meetingData.contacts[0].name }}</span>
												</template>
												<template v-else> <em>no person incharge</em></template>
											</div>
											<div class="mt-2 py-0">
												<span class="fw-600 mr-2">Added at: </span>
												<template v-if="meetingData && meetingData.customer_added_at">
													<span class="fw-500">{{ formatDate(meetingData.customer_added_at) }}</span>
												</template>
												<template v-else> <em>no lead date</em></template>
											</div>
										</v-col>
										<v-col cols="12">
											<div class="mt-2 py-0 d-flex">
												<span class="fw-600 text-no-wrap" style="width: 100px">Assigned To : </span>
												<span
													class="fw-500"
													v-if="attendieList && attendieList.attendies && attendieList.attendies.length"
												>
													<v-chip
														class="mb-1 mr-1"
														small
														v-for="(data, index) in attendieList.attendies"
														:key="index"
													>
														<v-avatar left small>
															<img v-if="data.profile_img" :src="data.profile_img" />
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-avatar>
														<template v-if="data && data.display_name">{{ data.display_name }}</template>
													</v-chip>
												</span>
												<em v-else class="text-muted"> no assign</em>
											</div>
										</v-col>
										<v-col cols="12">
											<div class="d-flex mt-2 py-0 w-100" v-if="meetingData.meeting_type != 'zoom'">
												<span class="fw-600 mr-2 text-no-wrap">Location / URL: </span>

												<span class="fw-500 text-capitalize" v-if="meetingData && meetingData.location">
													{{ meetingData.location }}</span
												>
												<template v-else> <em>no Location</em></template>
											</div>
											<div class="mt-2 py-0 d-flex w-100" v-if="meetingData && meetingData?.started_time">
												<span class="fw-600 mr-2 text-no-wrap">Meeting Started Time: </span>
												<span v-if="meetingData && meetingData?.started_time">
													<p class="fw-500" v-html="meetingData?.started_time"></p>
												</span>
												<template v-else> <em>no started time</em></template>
											</div>
											<div class="mt-2 py-0 d-flex w-100" v-if="meetingData && meetingData?.completed_time">
												<span class="fw-600 mr-2 text-no-wrap">Meeting Completed Time: </span>
												<span v-if="meetingData && meetingData?.completed_time">
													<p class="fw-500" v-html="meetingData?.completed_time"></p>
												</span>
												<template v-else> <em>no started time</em></template>
											</div>
											<div class="mt-2 py-0 d-flex w-100">
												<span class="fw-600 mr-2 text-no-wrap">Description : </span>
												<span v-if="meetingData && meetingData.description">
													<p class="fw-500" v-html="meetingData.description"></p>
												</span>
												<template v-else> <em>no description</em></template>
											</div>

											<div class="mt-2 py-0 d-flex w-100" v-if="meetingData.status == 1">
												<span class="fw-600 mr-2 text-no-wrap">Completed Remark : </span>
												<span v-if="meetingData && meetingData.completed_remark">
													<p class="fw-500" v-html="meetingData.completed_remark"></p>
												</span>
												<template v-else> <em>no remark</em></template>
											</div>
											<div class="mt-2 py-0 d-flex w-100" v-if="meetingData.status == 2">
												<span class="fw-600 mr-2 text-no-wrap">Cancelled Remark : </span>
												<span v-if="meetingData && meetingData.cancel_remark">
													<p class="fw-500" v-html="meetingData.cancel_remark"></p>
												</span>
												<template v-else> <em>no remark</em></template>
											</div>
											<div class="mt-2 py-0 d-flex w-100" v-if="meetingData.status == 3">
												<span class="fw-600 mr-2 text-no-wrap">Postponed Remark : </span>
												<span v-if="meetingData && meetingData.postponed_remark">
													<p class="fw-500" v-html="meetingData.postponed_remark"></p>
												</span>
												<template v-else> <em>no remark</em></template>
											</div>

											<div class="mt-2 py-0 d-flex w-100" v-if="meetingData.meeting_type == 'zoom'">
												<span class="fw-600 mr-2 text-no-wrap">Start Url : </span>
												<span class="fw-500 text-capitalize" v-if="meetingData && meetingData.host_url">
													<a :href="meetingData.host_url" target="_blank" class="mr-1" rel="noopener noreferrer"
														>Meeting start url</a
													>
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<span v-on="on" v-bind="attrs">
																<i class="fa-solid fa-clock-rotate-left"></i>
																<v-icon
																	v-on:click.prevent.stop="copyNumber(meetingData.host_url)"
																	class="mdi-16px ml-1"
																	>mdi-content-copy</v-icon
																>
															</span>
														</template>
														<span>Copy URL</span>
													</v-tooltip>
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<span v-on="on" v-bind="attrs">
																<i class="fa-solid fa-clock-rotate-left"></i>
																<v-icon v-on:click="sendMail()" class="mdi-16px ml-1">mdi-share-variant</v-icon>
															</span>
														</template>
														<span>Share</span>
													</v-tooltip>
												</span>
											</div>
											<div class="mt-2 py-0 d-flex w-100" v-if="meetingData.meeting_type == 'zoom'">
												<span class="fw-600 mr-2 text-no-wrap">Join Url : </span>
												<span class="fw-500 text-capitalize" v-if="meetingData && meetingData.join_url">
													<a :href="meetingData.host_url" target="_blank" class="mr-1" rel="noopener noreferrer"
														>Meeting Join url</a
													>
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<span v-on="on" v-bind="attrs">
																<i class="fa-solid fa-clock-rotate-left"></i>
																<v-icon
																	v-on:click.prevent.stop="copyNumber(meetingData.join_url)"
																	class="mdi-16px ml-1"
																	>mdi-content-copy</v-icon
																>
															</span>
														</template>
														<span>Copy URL</span>
													</v-tooltip>
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<span v-on="on" v-bind="attrs">
																<i class="fa-solid fa-clock-rotate-left"></i>
																<v-icon v-on:click="sendMail()" class="mdi-16px ml-1">mdi-share-variant</v-icon>
															</span>
														</template>
														<span>Share</span>
													</v-tooltip>
												</span>
											</div>
										</v-col>
									</v-row>
								</v-flex>
								<div class="detail-card bg-white" v-if="false">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">Meeting Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<table class="w-100">
											<tr>
												<td class="text-muted fw-500 px-3 py-2">Meeting Type</td>
												<td class="fw-600 px-3 py-1">
													<!-- <template v-if="meetingData && meetingData.meeting_type == 'zoom'"> Zoom </template>
													<template v-if="meetingData && meetingData.meeting_type == 'bthrust'">
														Face to Face Bthrust
													</template>
													<template v-if="meetingData && meetingData.meeting_type == 'client'">
														Face to Face Client
													</template>
													<template v-if="meetingData && meetingData.meeting_type == 'other'"> Other </template> -->
													<template v-if="meetingData && meetingData.meeting_type == 'zoom'">
														<v-chip
															class="ml-2 text-capitalize"
															color="blue white--text"
															outlined
															x-small
															label
															v-if="meetingData && meetingData.meeting_type"
															>{{ meetingData.meeting_type }}
														</v-chip>
													</template>
													<template v-if="meetingData && meetingData.meeting_type == 'bthrust'">
														<v-chip
															class="ml-2 text-capitalize"
															color="red white--text"
															x-small
															outlined
															label
															v-if="meetingData && meetingData.meeting_type"
															>F2F Bthrust
														</v-chip>
													</template>
													<template v-if="meetingData && meetingData.meeting_type == 'client'">
														<v-chip
															class="ml-2 text-capitalize"
															color="green white--text"
															outlined
															x-small
															label
															v-if="meetingData && meetingData.meeting_type"
															>F2F Client
														</v-chip>
													</template>
													<template v-if="meetingData && meetingData.meeting_type == 'other'">
														<v-chip
															class="ml-2 text-capitalize"
															color="orange white--text"
															x-small
															outlined
															label
															v-if="meetingData && meetingData.meeting_type"
															>Zoom
														</v-chip>
													</template>
												</td>
											</tr>
											<tr v-if="meetingData && meetingData.mobile_start_date">
												<td class="text-muted fw-500 px-3 py-2">External Start Date</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="meetingData && meetingData.mobile_start_date">
														{{ meetingData.mobile_start_date }}</template
													>
													<template v-else> <em>no check in</em></template>
												</td>
											</tr>
											<tr v-if="meetingData && meetingData.mobile_end_date">
												<td class="text-muted fw-500 px-3 py-2">External Start End</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="meetingData && meetingData.mobile_end_date">
														{{ meetingData.mobile_end_date }}</template
													>
													<template v-else> <em>no company name</em></template>
												</td>
											</tr>
											<tr v-if="meetingData && meetingData.longitude">
												<td class="text-muted fw-500 px-3 py-2">Longitude</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="meetingData && meetingData.longitude">
														{{ meetingData.longitude }}</template
													>
													<template v-else> <em>no longitude</em></template>
												</td>
											</tr>
											<tr v-if="meetingData && meetingData.longitude">
												<td class="text-muted fw-500 px-3 py-2" v-if="meetingData && meetingData.latitude">
													Latitude
												</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="meetingData && meetingData.latitude">
														{{ meetingData.latitude }}</template
													>
													<template v-else> <em>no latitude</em></template>
												</td>
											</tr>
											<!-- <tr>
												<td class="text-muted fw-500 px-3 py-2">Location</td>
												<td class="fw-600 px-3 py-1">
													<template class="fw-500 text-capitalize" v-if="meetingData && meetingData.location">
														{{ meetingData.location }}</template
													>
													
												</td>
											</tr> -->
											<tr>
												<td class="text-muted fw-500 px-3 py-2">Description</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="meetingData && meetingData.description">
														<div v-html="meetingData.description"></div
													></template>
													<template v-else> <em>no Description</em></template>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</v-tab-item>
							<v-tab-item value="notes">
								<AllNotesTab
									:relatedId="meetingId"
									:relatedType="'meeting-notes'"
									create-url="meeting-note"
									get-url="meeting-note"
									:singleData="meetingData"
								>
								</AllNotesTab>
								<!-- <template>
											<div>
												<div class="inbox">
													<template v-for="(data, index) in meetingMsg">
														<div :key="index">
															<span class="divider-by-day"
																v-if="data && data.label"><span>{{ data.label }}</span></span>
															<div :class="data.color">
																<div class="message" v-if="data && data.remark">
																	{{ data.remark }}<span>{{ data.added_at }}</span></div>
															</div>

														</div>
													</template>
													<div class="textarea">
														<v-textarea hide-details class="ma-0" outlined rows="2"
															v-model="meetingsdata.meeting_remark"> </v-textarea>

													</div>
												</div>
											</div>
											<v-btn class="white--text" depressed color="blue darken-4" tile
												v-on:click="onSubmit()">
												<v-icon class="send-btn cursor-pointer">mdi-send</v-icon>
											</v-btn>
											<v-btn depressed tile :disabled="pageLoading"
												v-on:click="closeDialog('remark')"> Close </v-btn>
								</template> -->
							</v-tab-item>
							<v-tab-item value="reminder">
								<v-layout class="px-4 py-2">
									<v-tabs
										background-color="transparent"
										color="blue"
										class="custom-tab-transparent w-100"
										active-class="blue darken-4 text-white"
										hide-slider
										v-model="reminderTabs"
									>
										<v-tab href="#overdue" v-on:click="getReminder(1)">
											<v-icon small left>mdi-alarm-check</v-icon>Overdue ({{
												reminderCounting && reminderCounting.overdue_reminders_count
													? reminderCounting.overdue_reminders_count
													: 0
											}})</v-tab
										>

										<v-tab href="#upcoming" v-on:click="getReminder(2)">
											<v-icon small left>mdi-alarm-check</v-icon>upcoming ({{
												reminderCounting && reminderCounting.upcoming_reminders_count
													? reminderCounting.upcoming_reminders_count
													: 0
											}})</v-tab
										>

										<v-tab href="#complete" v-on:click="getReminder(3)">
											<v-icon small left>mdi-alarm-check</v-icon>Completed ({{
												reminderCounting && reminderCounting.completed_reminders_count
													? reminderCounting.completed_reminders_count
													: 0
											}})</v-tab
										>
									</v-tabs>
								</v-layout>
								<div class="tab-layout-content px-4">
									<v-tabs-items v-model="reminderTabs">
										<template v-for="(tab, index) in ['overdue', 'upcoming', 'complete']">
											<v-tab-item :key="index + tab" :value="tab">
												<div v-if="reminderData && reminderData.length" class="mt-1 mx-2">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTab
															@editReminder="editReminderForm($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															@reminderSuccess="refreshReminder"
															:is-disabled="
																getStatusText() == 'Completed' || getStatusText() == 'Cancelled' ? true : false
															"
														>
														</ReminderTab>
													</div>
												</div>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no reminder at the moment.
													</p>
												</template>
											</v-tab-item>
										</template>
									</v-tabs-items>
								</div>
								<div>
									<!-- <div class="py-3">
										<div class="fs-16 fw-500 primary--text">April 28</div>
									</div> -->
									<!-- <div v-for="(reminder, index) in reminderData" :key="index">
										<ReminderTab
											v-on:editReminder="editReminderForm($event)"
											:open_details="!index ? true : false"
											:remind="reminder"
											:index-reminder="index"
											v-on:reminderSuccess="refreshReminder"
										>
										</ReminderTab>
									</div> -->
								</div>
							</v-tab-item>
							<v-tab-item value="timestamp">
								<MeetingTimestamp
									:related-id="meetingData.id"
									:meeting-timestamp="meetingTimestampData"
								></MeetingTimestamp>
							</v-tab-item>
							<v-tab-item value="file">
								<Files
									v-if="assetTab == 'file'"
									class="mx-4"
									type="meeting"
									:url="'meeting'"
									:type-id="meetingId"
								>
								</Files>
							</v-tab-item>
							<v-tab-item value="activity">
								<div class="pa-2">
									<v-timeline dense>
										<v-timeline-item
											color="blue darken-4"
											small
											v-for="(logs, index) in logData"
											:key="index"
										>
											<ActivityTab :open_details="true" :log="logs" title="Meeting"></ActivityTab>
										</v-timeline-item>
									</v-timeline>
								</div>
							</v-tab-item>
							<v-tab-item value="map">
								<GoogleMap></GoogleMap>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
			</v-layout>
		</div>
		<!-- sidebar drawers -->
		<CreateNote
			:drawer="open_dialog.notes"
			v-on:close="open_dialog.notes = false"
			:get-note-data="editNoteData"
			related="meeting"
			:relatedId="meetingId"
			v-on:updateSuccess="updateSuccessNote"
		></CreateNote>
		<CreateOrUpdateReminderV2
			v-if="open_dialog.reminder"
			:drawer="open_dialog.reminder"
			:reminderId="edit_reminder_id"
			related="meeting"
			:relatedId="meetingId"
			v-on:success="refreshReminder"
			v-on:close="closeReminderDialog()"
		>
		</CreateOrUpdateReminderV2>
		<!-- <CreateReminder
			v-if="open_dialog.reminder"
			:attendies="reminderattendieList"
			:drawer="open_dialog.reminder"
			:reminder-single-data="editReminderData.reminder"
			related="meeting"
			v-on:close="open_dialog.reminder = false"
			:relatedId="meetingId"
			v-on:updateRemindSuccess="refreshReminder"
		>
		</CreateReminder> -->
		<CreateMeeting
			v-if="open_dialog.meeting"
			:attendies="attendieList"
			:get-meeting-data="meetingData"
			:drawer="open_dialog.meeting"
			@close="open_dialog.meeting = false"
			@updateMeetingSuccess="refreshFunction"
			related="meeting"
			:relatedId="relatedId"
			:title-status="meeting_title"
		></CreateMeeting>
		<EmailTemplate
			:drawer="open_dialog.emailTemp"
			:related-id="meetingData.id"
			:attendies="mailAttendies"
			v-on:close="open_dialog.emailTemp = false"
			related="meeting"
		></EmailTemplate>
		<FullDialog v-if="meetingDialog" :dialog="meetingDialog" content-class="testdata">
			<template v-slot:title
				>Meeting {{ meetingData.barcode }} Notes
				<v-spacer></v-spacer>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialogRemark">
					Close
				</v-btn>
			</template>
			<template v-slot:body>
				<AllNotesTab
					:relatedId="meetingId"
					:relatedType="'meeting-notes'"
					create-url="meeting-note"
					get-url="meeting-note"
					:singleData="meetingData"
					:siggest-list="attendieList.attendies"
				>
				</AllNotesTab>
			</template>
			<template v-slot:action>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialogRemark">
					Close
				</v-btn>
			</template>
		</FullDialog>
		<div class="chat-icon">
			<div class="call-btn-underlay" style="background-color: rgb(43, 149, 105)"></div>
			<v-icon style="font-size: 50px !important" color="green" small v-on:click="meetingDialog = true"
				>mdi-chat
			</v-icon>
		</div>

		<MeetingApprovalDialog
			v-if="approvalDialog"
			:dialog="approvalDialog"
			:action-type="approvalActionType"
			:claim-id="meetingData.id"
			:claim-data="meetingData"
			@close="approvalDialog = false"
			@success="refreshFunction()"
		/>
	</v-sheet>
</template>
<script>
// import CreateReminder from "@/view/pages/leads/create/CreateReminder";
//import NotesTab from "@/view/pages/leads/components/NotesTab";
import CreateNote from "@/view/pages/leads/create/CreateNote";
import CreateOrUpdateReminderV2 from "@/view/pages/leads/create/CreateOrUpdateReminderV2";
import ActivityTab from "@/view/pages/leads/components/ActivityTab";
import ReminderTab from "@/view/pages/leads/components/ReminderTab";
import CreateMeeting from "@/view/pages/leads/create/CreateMeeting";
import EmailTemplate from "@/view/module/meeting/MailTemplateDrawar";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AllNotesTab from "@/view/pages/leads/components/NotesTab3";
import GoogleMap from "@/view/module/contacts/customer/components/Google-Map";
import MeetingTimestamp from "@/view/module/meeting/Meeting-Timestamp";
import { toSafeInteger, map } from "lodash";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import Files from "@/view/components/Files";
import { GET, QUERY } from "@/core/services/store/request.module"; //PATCH
import FullDialog from "@/view/components/FullDialog";
import MeetingApprovalDialog from "@/view/module/meeting/MeetingApprovalDialog.vue";
import objectPath from "object-path";

export default {
	name: "meeting-details",
	data() {
		return {
			currentStatus: { text: "All Meetings", value: "all" },
			relatedId: null,
			approvalDialog: false,
			approvalActionType: "mark_as_completed",
			search: null,
			open_detail: true,
			meetingId: null,
			edit_reminder_id: 0,
			meetingData: {},
			meetingLists: [],
			mettingStatus: [],
			statusData: [],
			reminderTabs: "overdue",
			meetingDialog: false,
			statusCount: null,
			meetingTimestampData: [],
			search_open: false,
			mailData: {},
			mailAttendies: {},
			open_dialog: {
				notes: false,
				meeting: false,
				reminder: false,
				emailTemp: false,
			},
			panel: [0],
			drawerCreate: false,
			/* 	assetTab: "overview", */
			task_action: false,
			remindersdata: [],
			viewType: false,
			open_details: false,
			assign_note: "Add Description",
			file_list: [],
			attendieList: {},
			reminderattendieList: {},
			reminderCounting: {},
			//	meetingMsg:[],
			logData: null,
			noteData: null,
			meeting_title: null,
			reminderData: [],
			editNoteData: null,
			editReminderData: {},
			related_type: "meeting",
			pageLoading: false,
			page: 1,
			/* meetingsdata:{
				meeting_remark :null,
			}, */
		};
	},
	components: {
		MeetingApprovalDialog,
		CreateNote,
		// CreateReminder,
		CreateOrUpdateReminderV2,
		ActivityTab,
		AllNotesTab,
		ReminderTab,
		CreateMeeting,
		FullDialog,
		EmailTemplate,
		MeetingTimestamp,
		Files,
		GoogleMap,
	},
	methods: {
		openDialog(action = "") {
			if (!action) return;

			this.approvalDialog = true;
			this.approvalActionType = action;
		},
		showLoadMoreBtn() {
			let alltasks = parseInt(objectPath.get(this.mettingStatus, "0.status_count"));
			if (alltasks && alltasks > 15 && alltasks > this.statusData.length) {
				return true;
			} else {
				return false;
			}
		},
		loadMoreMeetings() {
			if (this.pageLoading == false) {
				this.page++;
				this.statusFilter();
			}
		},
		// open_reminder_drawer(name) {
		// 	if (name == "Create") {
		// 		this.open_dialog.meeting = true;
		// 		this.meeting_title = name;
		// 	}
		// },
		// getReminderSetting() {
		// 	this.$store
		// 		.dispatch(GET, { url: `meeting-setting` })
		// 		.then((data) => {
		// 			//this.timeList = data.time;
		// 			//this.userList = data.users;
		// 		})
		// 		.catch((error) => {
		// 			console.log({ error });
		// 		});
		// },
		closeDialogRemark() {
			this.meetingDialog = false;
		},
		closeReminderDialog() {
			this.open_dialog.reminder = false;
			this.edit_reminder_id = 0;
		},
		getStatusActiveColor(status) {
			if (status == "Completed") {
				return "border-green";
			}
			if (status == "Cancelled") {
				return "border-red";
			}
			if (status == "Postponed") {
				return "border-blue";
			}
			if (status == "Upcoming") {
				return "border-gray";
			}
			if (status == "Started") {
				return "border-gray";
			}
			return "cyan";
		},
		create_reminder() {
			this.editReminderData = {};
			this.reminderattendieList = {};
			this.open_dialog.reminder = true;
		},
		sendMail() {
			console.log(this.attendieList);
			this.mailAttendies = map(this.attendieList.attendies, (row) => {
				return row.email;
			});

			this.open_dialog.emailTemp = true;
			console.log(this.mailAttendies);
			/* alert(this.mail_dialog)	   */
		},
		getStatusText() {
			if (this.meetingData.status == 1) {
				return "Completed";
			}
			if (this.meetingData.status == 2) {
				return "Cancelled";
			}
			if (this.meetingData.status == 3) {
				return "Postponed";
			}
			if (this.meetingData.status == 4) {
				return "Upcoming";
			}
			if (this.meetingData.status == 5) {
				return "Started";
			}
		},
		// getStatusTextBy(status=0) {
		// 	if (status == 1) {
		// 		return "Completed";
		// 	}
		// 	if (status == 2) {
		// 		return "Cancelled";
		// 	}
		// 	if (status == 3) {
		// 		return "Postponed";
		// 	}
		// 	if (status == 4) {
		// 		return "Upcoming";
		// 	}
		// },
		refreshFunction() {
			this.singleMeeting();
			this.statusFilter();
			this.meetingActivity();
			this.getReminder();
		},
		getStatusColor() {
			if (this.meetingData.status == 1) {
				return "green";
			}
			if (this.meetingData.status == 2) {
				return "red";
			}
			if (this.meetingData.status == 3) {
				return "blue";
			}
			if (this.meetingData.status == 4) {
				return "grey";
			}
			if (this.meetingData.status == 5) {
				return "grey";
			}
			return "cyan";
		},
		getStatusColorBy(status = "Upcoming") {
			if (status == "Completed") {
				return "green";
			}
			if (status == "Cancelled") {
				return "red";
			}
			if (status == "Postponed") {
				return "blue";
			}
			if (status == "Upcoming") {
				return "grey";
			}
			return "cyan";
		},
		getReminderType(data) {
			if (data == 1) {
				return "One Time Reminder";
			}
			if (data == 2) {
				return "Daily Reminder";
			}
			if (data == 3) {
				return "Weekly Reminder";
			}
			if (data == 4) {
				return "Monthly Reminder";
			}
			if (data == 5) {
				return "Quarterly Reminder";
			}
			if (data == 6) {
				return "Half Yearly Reminder";
			}
		},
		doAction(action) {
			if (action == "edit") {
				this.open_dialog.meeting = true;
				this.meeting_title = "Update";
				this.singleMeeting();
				return false;
			}
			this.pageLoading = true;

			this.openDialog(action);

			/*this.$store
				.dispatch(PATCH, {
					url: `meeting-status/${action}/${this.meetingId}`,
					data: this.holiday,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Meeting status update successfully.",
						},
					]);
					this.singleMeeting();
					this.getMeetingLists(true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});*/
		},
		pageTitle() {
			return "Meeting Details";
		},
		/* onSubmit() {
			this.pageLoading = true;
            this.$store
				.dispatch(POST, { url: `meeting-note/${this.meetingId}`, data: this.meetingsdata })
				.then(() => {
					
			
				
					this.meetingsdata.meeting_remark = '';
					this.getMeetingRemark();
					this.$refs.form.reset();
                  
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				}); 
		},
		getMeetingRemark() {
			this.$store
				.dispatch(GET, {
					url: `meeting-note/${this.meetingId}`,
				})
				.then((data) => {
					this.meetingMsg= data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		}, */
		uploadFile(data) {
			const file_size = (data.size / 1024).toFixed(1) + "kb";
			const data_opj = { icon: "mdi-file-check-outline", name: data.name, size: file_size };
			this.file_list.push(data_opj);
		},
		selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		},
		remove_file(index) {
			this.file_list.splice(index, 1);
		},
		closeSerach() {
			(this.search_open = false), (this.search = "");
		},

		getMeetingTimeStamp() {
			this.$store
				.dispatch(GET, { url: `meeting-timestamp/${this.meetingId}` })
				.then((data) => {
					this.meetingTimestampData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		singleMeeting() {
			this.$store
				.dispatch(GET, { url: `single-meeting/${this.meetingId}` })
				.then((data) => {
					this.meetingData = data.meeting;
					this.relatedId = this.meetingData ? this.meetingData.related_id : 0;
					this.meetingData.contacts = JSON.parse(data.meeting.contacts);
					this.remindersdata = JSON.parse(data.meeting.remindersdata);
					this.attendieList = data.attendie[0];
					/* console.log(typeof(this.attendieList)) */
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getMeetingLists(update_status_list = false) {
			this.$store
				.dispatch(QUERY, { url: `meeting`, data: { all: 1 } })
				.then((data) => {
					this.meetingLists = data.tbody;
					if (update_status_list) {
						this.mettingStatus = data.status;
					}
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		meetingActivity() {
			this.$store
				.dispatch(GET, { url: `meeting-log/${this.meetingId}` })
				.then((data) => {
					this.logData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getNotes() {
			this.$store
				.dispatch(QUERY, { url: `note`, data: { related_id: this.meetingId, related_to: "meeting" } })
				.then((data) => {
					this.noteData = data.rows;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getReminder(type) {
			if (!type) {
				this.reminderTabs = "overdue";
				type = 1;
			}
			this.$store
				.dispatch(QUERY, {
					url: `reminder`,
					data: { related_id: this.meetingId, related_to: "meeting", status: type ? type : 1 },
				})
				.then((data) => {
					if (type == 1) {
						this.reminderTabs = "overdue";
					} else if (type == 2) {
						this.reminderTabs = "upcoming";
					} else if (type == 3) {
						this.reminderTabs = "complete";
					}
					this.reminderData = data.rows;
					this.reminderCounting = data.countings ? data.countings : {};
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		// getReminder() {
		// 	this.$store
		// 		.dispatch(QUERY, {
		// 			url: `reminder`,
		// 			data: { related_id: this.meetingId, related_to: "meeting" },
		// 		})
		// 		.then((data) => {
		// 			this.reminderData = data.rows;
		// 		})
		// 		.catch((error) => {
		// 			console.log({ error });
		// 		});
		// },
		editNote(id) {
			this.open_dialog.notes = true;
			this.$store
				.dispatch(GET, { url: `single-note/${id}` })
				.then((data) => {
					this.editNoteData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		editReminderForm(id) {
			this.open_dialog.reminder = true;
			this.edit_reminder_id = id;
			// this.editReminderData = {};
			// this.reminderattendieList = {};
			// this.$store

			// 	.dispatch(GET, { url: `single-reminder/${id}/${this.related_type}` })
			// 	.then((data) => {
			// 		this.editReminderData = data;
			// 		this.reminderattendieList = data.attendie[0];
			// 	})
			// 	.catch((error) => {
			// 		console.log({ error });
			// 	});
		},
		tabDetails(id) {
			this.$router.push({
				name: "meeting-detail",
				params: { id },
				query: { ...this.$route.query, t: new Date().getTime() },
			});
			this.meetingId = id;
			this.singleMeeting();
			this.getMeetingTimeStamp();
			this.getMeetingLists();
			this.meetingActivity();
			this.getReminder();
		},
		statusFilter(value = this.currentStatus.value, text = this.currentStatus.text) {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, { url: `meeting`, data: { status: value, page: this.page } })
				.then((data) => {
					this.currentStatus = { text, value };
					this.mettingStatus = [...data.status];
					if (this.page == 1) {
						this.statusData = [...data.tbody];
					} else {
						this.statusData = [...this.statusData, ...data.tbody];
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		copyNumber(data) {
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},
		goBackData() {
			// let filterQuery = this.$route.query;
			// this.$router.push({
			// 	name: "meeting",
			// 	query: {
			// 		...filterQuery,
			// 		t: new Date().getTime(),
			// 		viewType: this.viewType,
			// 	},
			// });
			this.$router.go(-1);
		},
		searchMeeting($event) {
			const keySearch = $event.target.value;
			if (keySearch !== "") {
				this.$store
					.dispatch(QUERY, { url: `meeting`, data: { search: keySearch } })
					.then((data) => {
						this.statusData = data.tbody;
					})
					.catch((error) => {
						console.log({ error });
					});
			}
		},
		updateSuccessNote() {
			this.open_dialog.notes = false;
			this.getNotes();
		},
		refreshReminder(data) {
			this.getReminder(data.status);
		},
		userListing() {
			this.$router.push("/users");
		},
	},
	computed: {
		assetTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.assetTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "meeting";
			},
		},
	},
	mounted() {
		if (!this.getPermission("meeting:view") && this.meetingId > 0) {
			this.$router.replace({
				name: "meeting",
				query: { t: new Date().getTime() },
			});
		}
		this.singleMeeting();
		this.getMeetingTimeStamp();
		// this.getMeetingLists();
		this.meetingActivity();
		this.getNotes();
		this.getReminder();
		this.statusFilter();
		this.viewType = this.$route.query.viewType;
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Meeting", disabled: false, href: "/meeting" },
			{ text: "Detail", disabled: true },
		]);
	},
	beforeMount() {
		this.meetingId = toSafeInteger(this.$route.params.id);
	},
};
</script>
<style>
.chat-icon {
	position: fixed;
	z-index: 9;
	/* background-color: rgb(213, 245, 214); */
	/* right: 20px !important; */
	/* bottom: 5% !important;
	padding: 10px; */
	/* animation:cp-widget-button-underlay 1.5s infinite; */
}
</style>

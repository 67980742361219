<template>
	<v-sheet class="recurring-main-listing" style="height: calc(87vh - 88px)">
		<template>
			<div class="overflow-y" style="max-height: calc(100vh - 306px)">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th class="p-2 light-blue-bg custome-table">SR.No.</th>
							<th class="p-2 light-blue-bg custome-table">User Details</th>
							<!-- <th class="p-2 light-blue-bg custome-table">Checkin Date Time</th>
							<th class="p-2 light-blue-bg custome-table">Checkout Date Time</th> -->
							<th class="p-2 light-blue-bg custome-table">Longitude</th>
							<th class="p-2 light-blue-bg custome-table">Latitude</th>
							<th class="p-2 light-blue-bg custome-table">Attachments</th>
							<th class="p-2 light-blue-bg custome-table">Entry Type</th>
						</tr>
					</thead>
					<tbody v-if="meetingTimestamp.length">
						<tr v-for="(row, index) in meetingTimestamp" :key="index">
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue v-if="false" :object="row" object-key="id" label="sr"></ShowValue>
								{{ index + 1 }}
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<div class="d-flex align-items-center">
									<div style="width: 50px">
										<ImageTemplate circle style="max-width: 50px" :src="row.profile_img"> </ImageTemplate>
									</div>
									<div class="ml-2">
										<template v-if="row.user_name">
											<div>
												<label class="font-size-14 mb-1"
													><b>
														<v-icon size="18">mdi-account-circle</v-icon>
													</b></label
												>
												{{ row.user_name }}
											</div>
										</template>
										<template v-if="row.barcode">
											<div>
												<label class="font-size-14 mb-1"
													><b>
														<v-icon size="18">mdi-pound</v-icon>
													</b></label
												>
												{{ row.barcode }}
											</div>
										</template>
										<template v-if="row.email">
											<div>
												<label class="font-size-14 mb-1"
													><b>
														<v-icon size="18">mdi-email</v-icon>
													</b></label
												>
												{{ row.email }}
											</div>
										</template>
										<template v-if="row.phone_number">
											<div>
												<label class="font-size-14 mb-1"
													><b>
														<v-icon size="18">mdi-phone</v-icon>
													</b></label
												>
												{{ row.phone_number }}
											</div>
										</template>
									</div>
								</div>
							</td>
							<!-- <td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.added_at"> {{ formatDateTime(row.added_at) }} </span>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.added_at"> {{ formatDateTime(row.added_at) }} </span>
							</td> -->
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.longitude">
									{{ row.longitude }}
								</span>
								<em class="text-muted" v-else>no longitude</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.latitude">
									{{ row.latitude }}
								</span>
								<em class="text-muted" v-else>no latitude</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<template v-for="(attachmentInfo, index) in row.attachment">
									<div class="mb-1" :key="index">
										<template v-if="attachmentInfo.att_url">
											<div class="d-flex align-items-center">
												<div>
													<v-btn
														small
														icon
														depressed
														color="blue darken-4"
														class="mr-1"
														v-on:click="downloadAttachment(attachmentInfo.att_url)"
														><v-icon small>mdi-download</v-icon></v-btn
													>
												</div>
												<div class="ml-2">
													<div>
														{{ attachmentInfo.att_name }}
													</div>
												</div>
											</div>
										</template>
										<template v-else>
											<em class="text-muted ml-2"> no attachment</em>
										</template>
									</div>
								</template>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.entry_type">
									<Chip
										class="text-capitalize"
										:text="row.entry_type"
										color="green"
										text-color=""
										outlined
									></Chip>
								</span>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="7">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no file at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</template>
	</v-sheet>
</template>
<style>
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/* border-bottom: solid 1px #bcc0c9 !important; */
}

.custome-table {
	background-color: #f3f6f9;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
</style>
<script>
/* import ApiService from "@/core/services/api.service"; */
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import ImageTemplate from "@/view/components/Image";

export default {
	data() {
		return {
			defaultFilter: {},
			pageLoading: false,
		};
	},
	props: {
		meetingId: {
			type: Number,
			default: 0,
		},
		meetingTimestamp: {
			type: Array,
		},
	},
	methods: {
		/* getSeo(page) {
			ApiService.query(`seo-report`, { page: page, search: this.DataSearch })
				.then(({ data }) => {
					this.seoReport = data.tbody;
					this.showing_string = data.showing_string;
					this.total_page = data.total_page;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
	},
	components: {
		ShowValue,
		Chip,
		ImageTemplate,
	},
	mounted() {
		/* this.getSeo(); */
	},
};
</script>
